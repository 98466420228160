function escapeHtml(str) {
	var div = document.createElement('div');
	div.appendChild(document.createTextNode(str));
	return div.innerHTML;
}
function initLocationAutocomplete() {

	var location_options = {
		types: [],
		fields: ["address_components", "name", "geometry.location"]
		// componentRestrictions: { country: 'us' },
	};
	autocomplete = new window.google.maps.places.Autocomplete($('#g-place-input')[0], location_options);

	autocomplete.addListener("place_changed", function() {
		const place = autocomplete.getPlace();

		if (!place.geometry) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            window.alert(
              "No place was found for your search: '" + place.name + "'. Please try entering another location and choose from the suggestions."
            );
            return false;
		}

		var selectedCountry = place.address_components.filter(function (item) {
			return item.types[0] === "country";
		})[0];

		var loc = document.getElementById("loc");
		var lat = document.getElementById("lat");
		var lng = document.getElementById("lng");
		var country = document.getElementById("country");

		lat.value = place.geometry.location.lat().toFixed(2);
		lng.value = place.geometry.location.lng().toFixed(2);
		country.value = selectedCountry.short_name.trim();

		var safename = escapeHtml(place.name.trim());
		loc.value = safename
		$('#event-location-display').text(safename);

	});
}
$(document).ready(function () {
	
	$('#g-place-input').click(function(){
		$('.pac-container').insertAfter($('#g-place-input').parents('.form-item-wrap'));
		$(this).select();
	});

});